<template>
	<div>
		<!-- <h1>Progressive Settings</h1> -->
		<div id="progressives-container">
			<fieldset>
				<legend>Set Progressive Limits</legend>
				<div v-if="casinoList.length > 1">
					<label for="casinoOptions">Casino</label>
					<select title="Select Casino" id="casinoOptions" v-model="selectedCasinoId">
						<option v-for="(item, index) in casinoList" :key="index" :value="item.id">{{ item.name }}</option>
					</select>
					<button class="btn refresh" @click="getProgressiveDetails()">Refresh</button>
				</div>
				<div v-if="serverBusy" id="progressives-loading">
					<span class="loading-icon"></span>
					<span class="loading-message">{{ busyText }}</span>
				</div>
				<div v-if="!serverBusy">
					<p class="center bold">
						Current Limit:
						<span>{{
							maxEnabledProgTotalPrizeAU ? systemCurrencyTool.formatFromAU(maxEnabledProgTotalPrizeAU, systemFull) : "No limit"
						}}</span>
					</p>
					<table>
						<tr>
							<th class="header left">Limit</th>
							<th class="header center">Enabled</th>
							<th class="header center">Installed</th>
						</tr>
						<tr>
							<td class="header left">Max Total Progressive Prize</td>
							<td class="data right" v-if="maxEnabledProgTotalPrizeAU">
								{{ systemCurrencyTool.formatFromAU(maxEnabledProgTotalPrizeAU, systemFull) }}
							</td>
							<td class="data center" v-else>No limit</td>
							<td class="data right" v-if="maxProgTotalPrizeAU">
								{{ systemCurrencyTool.formatFromAU(maxProgTotalPrizeAU, systemFull) }}
							</td>
							<td class="data center" v-else>No limit</td>
						</tr>
						<tr
							:title="
								maxEnabledProgTotalPrizeAU <= maxEnabledProgTotalPrizeAU ||
								maxEnabledProgTotalPrizeAU <= maxEnabledProgPaytablePrizeAU
									? 'Some progressive pots will be limited to zero, regardless of contribution settings.'
									: ''
							"
							:class="
								maxEnabledProgTotalPrizeAU <= maxEnabledProgTotalPrizeAU ||
								maxEnabledProgTotalPrizeAU <= maxEnabledProgPaytablePrizeAU
									? 'warn'
									: ''
							"
						>
							<td class="header left">
								Max Paytable
								{{
									maxEnabledProgTotalPrizeAU <= maxEnabledProgTotalPrizeAU ||
									maxEnabledProgTotalPrizeAU <= maxEnabledProgPaytablePrizeAU
										? "!!"
										: ""
								}}
							</td>
							<td class="data right" v-if="maxEnabledProgTotalPrizeAU">
								{{ systemCurrencyTool.formatFromAU(maxEnabledProgPaytablePrizeAU, systemFull) }}
							</td>
							<td class="data center" v-else>No limit</td>
							<td class="data right" v-if="maxProgPaytablePrizeAU">
								{{ systemCurrencyTool.formatFromAU(maxProgPaytablePrizeAU, systemFull) }}
							</td>
							<td class="data center" v-else>No limit</td>
						</tr>
						<tr
							:title="
								maxEnabledProgTotalPrizeAU <= maxEnabledProgTotalPrizeAU ||
								maxEnabledProgTotalPrizeAU <= maxEnabledProgPaytablePrizeAU
									? 'Some non-progressive prizes exceed the progressive limit.'
									: ''
							"
							:class="maxEnabledProgTotalPrizeAU <= maxEnabledNonProgPrizeAU ? 'warn' : ''"
						>
							<td class="header left">
								Max Non-Progressive
								{{
									maxEnabledProgTotalPrizeAU <= maxEnabledProgTotalPrizeAU ||
									maxEnabledProgTotalPrizeAU <= maxEnabledProgPaytablePrizeAU
										? "!!"
										: ""
								}}
							</td>
							<td class="data right" v-if="maxEnabledNonProgPrizeAU">
								{{ systemCurrencyTool.formatFromAU(maxEnabledNonProgPrizeAU, systemFull) }}
							</td>
							<td class="data center" v-else>No limit</td>
							<td class="data center">N/A</td>
						</tr>
					</table>
				</div>
				<div v-if="!serverBusy" class="input-container">
					<label for="progressiveValueCurrency" title="Set Progressive Currency">New Limit for Max Total Prize</label>
					<div class="flex-row">
						<span>{{ systemCurrencyTool?.currencyInfo?.symbol }}</span>
						<input
							id="progressive"
							type="hidden"
							name="progressive"
							title="Progressive Value AU"
							v-model="progressiveValueAU"
							@keyup.enter="saveProgressives()"
						/>
						<input
							id="progressiveValueCurrency"
							type="number"
							name="progressiveValueCurrency"
							title="Progressive Value Currency"
							v-model="progressiveValueCurrency"
							@keyup.enter="saveProgressives()"
						/>
					</div>
					<span>{{ systemCurrencyTool.formatFromAU(progressiveValueAU, systemFull) }}</span>
					<label for="has-limit" title="Has Limit">Has Limit</label>
					<input id="has-limit" type="checkbox" name="isLimitSet" title="Has Limit" v-model="isLimitSet" />
					<button class="btn" @click="saveProgressives()">Save Progressive Limit</button>
				</div>
				<div id="description-container" class="left">
					<p>
						This feature allows you to set progressive limits by specifying the MAX TOTAL PROGRESSIVE PRIZE for the whole Casino. Each
						progressive pot's limit - which is not shown here - is automatically adjusted by the server so that total prize can't exceed
						the limit you set. However this setting ONLY affects progressive pot limits, so it cannot restrict the paytable portions of
						wins (progressive or non-progressive) for enabled games.
					</p>
					<p>
						The ENABLED column refers to games/denoms currently enabled. The INSTALLED column refers to all games available on the server,
						whether they're enabled or not.
					</p>
					<p>
						The MAX TOTAL PROGRESSIVE PRIZE row shows the currently set maximum prize for a progressive win, including both the paytable
						base and the progressive pot.
					</p>
					<p>
						The MAX PAYTABLE PRIZE row shows the largest possible win from the paytable on a progressive-winning play - i.e., the base
						value. It is not meaningful to set a limit lower than the value in the ENABLED column. If the value in the ENABLED column is
						higher than the maximum you're prepared to pay out, try disabling the highest currently-enabled denom for all games and return
						here to check again.
					</p>
					<p>
						The MAX NON-PROGRESSIVE PRIZE row shows the larget possible win from the paytable on a non-progressive-winning play. The limit
						here has no effect on this value. If the value in the ENABLED column is higher than the maximum you're prepared to pay out,
						try disabling the highest currently-enabled denom for all games and return here to check again.
					</p>
					<p>If game titles and/or denoms are ever re-enabled, please return here to review the limits.</p>
				</div>
			</fieldset>
		</div>
	</div>
</template>

<script>
import sharedScripts from "@/dependencies/sharedScripts";

export default {
	name: "ProgressiveLimits",
	props: {
		adminState: Object,
		casinoList: Array,
		systemCurrencyTool: Object,
	},
	data() {
		return {
			systemMinorWholeOrFull: this.systemCurrencyTool.displayType.minorWholeOrFull,
			systemFull: this.systemCurrencyTool.displayType.full,
			status: Object.assign({}, this.globalStatus),
			serverBusy: false,
			busyText: "",
			description: "",
			maxProgPaytablePrizeAU: 0,
			maxEnabledProgPaytablePrizeAU: 0,
			maxEnabledNonProgPrizeAU: 0,
			isLimitSet: false,
			maxProgTotalPrizeAU: 0,
			maxEnabledProgTotalPrizeAU: 0,
			selectedCasinoId: this.adminState.casinoId,
			casinoName: "",
			progressiveValueAU: 0,
			progressiveValueCurrency: 0,
			isLimitSet: false,
		};
	},
	watch: {
		selectedCasinoId() {
			this.getProgressiveDetails();
		},
		progressiveValueCurrency() {
			this.progressiveValueAU = this.systemCurrencyTool.toAUFromCurrency(this.progressiveValueCurrency);
		},
	},
	created() {
		this.getProgressiveDetails();
	},
	activated() {
		this.getProgressiveDetails();
	},
	mounted() {},
	methods: {
		async saveProgressives() {
			this.busyText = "Saving Progressives";
			this.serverBusy = true;
			this.status.ok = true;

			if (this.status.ok === false) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			let body = {
				CasinoId: this.selectedCasinoId,
				HasLimit: this.isLimitSet,
				MaxTotalPrizeAU: this.isLimitSet ? this.progressiveValueAU : 0,
			};

			let requestUrl = new URL("/api/v1/admin/progressives/limits", this.rabbitsfootHostUrl);
			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.adminState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let request = new Request(requestUrl.toString(), {
				method: "POST",
				body: JSON.stringify(body),
				headers: headerObj,
			});

			try {
				let response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response);

				if (fetchStatus && !fetchStatus.ok) {
					this.serverBusy = false;
					this.busyText = "";
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout) this.eventBus.emit("forceLogout");
					return false;
				}

				let dataJson = await response.json();

				this.casinoName = this.casinoList.filter((casino) => casino.id == this.selectedCasinoId)[0]?.name;

				this.maxProgPaytablePrizeAU = dataJson.maxProgPaytablePrizeAU;
				this.maxEnabledProgPaytablePrizeAU = dataJson.maxEnabledProgPaytablePrizeAU;
				this.maxEnabledNonProgPrizeAU = dataJson.maxEnabledNonProgPrizeAU;
				this.isLimitSet = dataJson.isLimitSet;
				this.maxProgTotalPrizeAU = dataJson.maxProgTotalPrizeAU;
				this.maxEnabledProgTotalPrizeAU = dataJson.maxEnabledProgTotalPrizeAU;
				this.progressiveValueAU = dataJson.maxEnabledProgTotalPrizeAU;
				this.progressiveValueCurrency = this.systemCurrencyTool.toCurrencyFromAU(dataJson.maxEnabledProgTotalPrizeAU);

				this.status.message = `${this.casinoName} progressives saved successfully`;
				this.status.code = fetchStatus.code;
				this.status.ok = fetchStatus.ok;
				this.eventBus.emit("updateStatus", this.status);
				this.serverBusy = false;
				this.busyText = "";
			} catch (e) {
				this.status.code = e;
				this.status.ok = false;
				this.userMustDismiss = true;
				this.eventBus.emit("updateStatus", this.status);
				console.error(e);
				return false;
			}
		},
		async getProgressiveDetails() {
			this.busyText = "Loading Progressives";
			this.serverBusy = true;

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			let requestUrl = new URL("/api/v1/admin/progressives/limits/", this.rabbitsfootHostUrl);
			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.adminState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");

			let params = requestUrl.searchParams;
			params.set("casinoId", this.selectedCasinoId);

			requestUrl.search = params.toString();

			let request = new Request(requestUrl.toString(), {
				method: "GET",
				headers: headerObj,
			});

			try {
				const response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response);

				if (fetchStatus && !fetchStatus.ok) {
					this.serverBusy = false;
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout) this.eventBus.emit("forceLogout");
					return;
				}

				let dataJson = await response.json();

				this.maxProgPaytablePrizeAU = dataJson.maxProgPaytablePrizeAU;
				this.maxEnabledProgPaytablePrizeAU = dataJson.maxEnabledProgPaytablePrizeAU;
				this.maxEnabledNonProgPrizeAU = dataJson.maxEnabledNonProgPrizeAU;
				this.isLimitSet = dataJson.isLimitSet;
				this.maxProgTotalPrizeAU = dataJson.maxProgTotalPrizeAU;
				this.maxEnabledProgTotalPrizeAU = dataJson.maxEnabledProgTotalPrizeAU;
				this.progressiveValueAU = dataJson.maxEnabledProgTotalPrizeAU;
				this.progressiveValueCurrency = this.systemCurrencyTool.toCurrencyFromAU(dataJson.maxEnabledProgTotalPrizeAU);

				this.serverBusy = false;
				this.busyText = "";
			} catch (e) {
				console.error(e);
			}
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#progressives-loading {
	text-align: center;
	font-weight: bold;
	margin: 15px;
}

#progressives-container {
	margin: 15px 15px 30px;
	padding: 15px;
	background-color: #ddd;
	color: #000;
}

#progressiveValueCurrency {
	margin-left: 2px;
	padding-left: 0;
}

.input-container {
	padding: 10px;
	background-color: #393939;
	border-radius: 8px;
	box-shadow: 3px 3px 6px rgb(0 0 0 / 60%);
	color: #fff;
}

.flex-row {
	display: flex;
	flex-direction: row nowrap;
	align-items: center;
}

table {
	position: relative;
	width: 50%;
	margin: auto auto 15px;
	border-collapse: collapse;
	text-align: center;
	background-color: #fff;
	box-shadow: 0px 6px 10px 0px rgb(0 0 0 / 50%);
}

.header {
	background-color: #000;
	color: #fff;
	border: 1px #fff solid;
}

.data,
.header {
	padding: 5px;
}

.data {
	border: 1px #000 solid;
}

.bold {
	font-weight: bold;
}

.center {
	text-align: center;
}

.right {
	text-align: right;
}

.left {
	text-align: left;
}

fieldset {
	margin: auto;
}

fieldset div,
.btn {
	display: block;
	margin: 15px auto;
	text-align: center;
}

.input-container {
	/* display block above is causing me to have to use important. will fix it later. */
	display: flex !important;
	flex-direction: column;
}

.refresh {
	display: inline-block;
}

.warn {
	background-color: #ff0;
	color: #000;
	cursor: help;
}

.warn .header {
	color: #ff0;
}

@media (min-width: 768px) {
	#description-container,
	.input-container {
		width: 50%;
	}
}
</style>
